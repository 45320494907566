import { React, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button, LinkButton } from "../../buttons"
import { s } from "../../../style/constants/sizes"
import useDisableBodyScroll from "../../../utils/useDisableBodyScroll"
import useWindowSize from "../../../utils/useWindowSize"

const CheckoutNav = ({
  isCheckoutNavOpen,
  setIsCheckoutNavOpen,
  services,
  dispatch,
}) => {
  const size = useWindowSize()
  useDisableBodyScroll(isCheckoutNavOpen & (size.width < 500))
  const [hoverDeleteId, setHoverDeleteId] = useState(null)
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          dir: { regex: "/media/images/icons/" }
          name: { regex: "/x256/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                width: 45
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      close: file(name: { regex: "/close_round_icon/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 25, placeholder: BLURRED, formats: AUTO)
        }
      }
      bin_close: file(name: { regex: "/bin_close/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 35, placeholder: BLURRED, formats: AUTO)
        }
      }
      bin_open: file(name: { regex: "/bin_open/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 35, placeholder: BLURRED, formats: AUTO)
        }
      }
    }
  `)
  const closeButton = getImage(data.close)
  return (
    <div
      css={{
        position: "fixed",
        top: 0,
        right: isCheckoutNavOpen ? 0 : -300,
        height: "100%",
        width: "300px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
        backdropFilter: "blur(20px)",
        transition: "right .4s linear",
        padding: isCheckoutNavOpen ? 10 : 0,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        overflow: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
        [s.x500]: {
          width: "100vw",
          right: isCheckoutNavOpen ? 0 : "-100vw",
          padding: 0,
        },
      }}
    >
      <div
        css={{
          textAlign: "center",
          [s.x500]: {
            padding: 10,
          },
        }}
      >
        <div
          onClick={() => {
            setIsCheckoutNavOpen(false)
          }}
          css={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              border: "2px solid white",
              borderRadius: "50%",
              padding: 3,
            }}
          >
            <GatsbyImage image={closeButton} alt="Close" />
          </div>
        </div>

        <h3 css={{ fontSize: "1.5rem" }}>Checkout</h3>
        {services.length === 0 && (
          <p>There is no services in the checkout...</p>
        )}
        <div>
          {services.map((service, id) => {
            const image = getImage(
              data.allFile.edges.filter(e =>
                e.node.name.includes(service.icon)
              )[0].node
            )
            return (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <GatsbyImage image={image} alt="" />
                <p>{service.name}</p>
                {hoverDeleteId === id ? (
                  <div
                    onMouseLeave={() => {
                      setHoverDeleteId(null)
                    }}
                    onClick={() => {
                      const storageServices = services.filter(
                        e => e !== service
                      )
                      dispatch({ type: "DELETE_SERVICE", payload: service })
                      sessionStorage.setItem(
                        "servicesAdded",
                        JSON.stringify(storageServices)
                      )
                      setHoverDeleteId(null)
                    }}
                    css={{ cursor: "pointer" }}
                  >
                    <GatsbyImage image={getImage(data.bin_open)} alt="delete" />
                  </div>
                ) : (
                  <div
                    onMouseEnter={() => {
                      setHoverDeleteId(id)
                    }}
                    onClick={() => {
                      const storageServices = services.filter(
                        e => e !== service
                      )
                      dispatch({ type: "DELETE_SERVICE", payload: service })
                      sessionStorage.setItem(
                        "servicesAdded",
                        JSON.stringify(storageServices)
                      )
                      setHoverDeleteId(null)
                    }}
                    css={{ cursor: "pointer" }}
                  >
                    <GatsbyImage
                      image={getImage(data.bin_close)}
                      alt="delete"
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0px",
          gap: "10px",
        }}
      >
        <LinkButton
          link={"/contact"}
          title={"Contact Us"}
          color={"rgb(39,148,209)"}
        />
        <LinkButton
          link={"/services"}
          title={"Services"}
          color={"rgb(0, 204, 102)"}
        />
      </div>
    </div>
  )
}

export default CheckoutNav
