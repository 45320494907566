import { React } from "react"

const Button = ({ title, func, color, additionalCss, ...props }) => {
  const style = {
    button: {
      color: "#fff",
      width: 130,
      height: 40,
      padding: "10px 25px",
      fontWeight: 500,
      background: "transparent",
      cursor: "pointer",
      transition: "all 0.3s ease",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      backgroundColor: `${color}`,
      boxShadow: `0 0 5px ${color}`,
      borderRadius: "15px",
      fontSize: "1.3rem",
      ":hover": {
        boxShadow:
          !props.isServiceAlreadyAdded &&
          `0 0 10px ${color}, 0 0 20px ${color}, 0 0 20px #fff inset`,
      },
    },
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={func}
      css={[style.button, additionalCss]}
    >
      {title}
    </div>
  )
}

export default Button
