import React, { useState } from "react"
import NeonWrapper from "./../../wrappers/neonWrap"
import { s } from "../../../style"
import NeonToggleButton from "./NeonToggleButton"

const NeonOptions = ({
  setOptionsActive,
  dispatch,
  neonsActualColor,
  neonsOn,
}) => {
  const [neonColor, setNeonColor] = useState(neonsActualColor)
  return (
    <NeonWrapper
      additionalCss={{
        display: "inline-block",
        position: "fixed",
        bottom: 25,
        left: 25,
        marginTop: "30px",
        backgroundColor: "black",
        zIndex: 999,
        width: 350,
        padding: 10,
        [s.x500]: {
          bottom: 0,
          left: 0,
          width: "100%",
          padding: 0,
        },
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          position: "relative",
        }}
      >
        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <NeonToggleButton
            dispatch={dispatch}
            neonsActualColor={neonsActualColor}
            neonsOn={neonsOn}
          />
          <span
            css={{
              color: neonsOn ? neonsActualColor : "white",
              cursor: "pointer",
              fontWeight: "900",
            }}
            onClick={() => {
              setOptionsActive(false)
            }}
          >
            X
          </span>
        </div>
        <h3
          css={{
            width: "100%",
            margin: "5px 0px",
            [s.x500]: {
              textAlign: "center",
            },
          }}
        >
          Chose Your Color:
        </h3>
        <div
          css={{
            display: "flex",
            gap: 10,
            [s.x500]: {
              justifyContent: "center",
              alignItems: "center",
              gap: 50,
            },
          }}
        >
          <div
            css={{
              width: "30px",
              height: "30px",
              backgroundColor: "red",
              border: "1px solid white",
              borderRadius: "15px",
              opacity: "0.7",
              cursor: "pointer",
              ":hover": {
                opacity: "1",
              },
              [s.x500]: {
                width: "50px",
                height: "50px",
              },
            }}
            onClick={() => {
              dispatch({ type: "CHANGE_NEON_COLOR", payload: "rgb(255, 0, 0)" })
            }}
          />
          <div
            css={{
              width: "30px",
              height: "30px",
              backgroundColor: "blue",
              border: "1px solid white",
              borderRadius: "15px",
              opacity: "0.7",
              cursor: "pointer",
              ":hover": {
                opacity: "1",
              },
              [s.x500]: {
                width: "50px",
                height: "50px",
              },
            }}
            onClick={() => {
              dispatch({ type: "CHANGE_NEON_COLOR", payload: "rgb(0, 0, 255)" })
            }}
          />
          <div
            css={{
              width: "30px",
              height: "30px",
              backgroundColor: "green",
              border: "1px solid white",
              borderRadius: "15px",
              opacity: "0.7",
              cursor: "pointer",
              ":hover": {
                opacity: "1",
              },
              [s.x500]: {
                width: "50px",
                height: "50px",
              },
            }}
            onClick={() => {
              dispatch({ type: "CHANGE_NEON_COLOR", payload: "rgb(0, 255, 0)" })
            }}
          />
        </div>
        <h4
          css={{
            margin: "5px 0px",
            [s.x500]: {
              textAlign: "center",
            },
          }}
        >
          Or type it here:
        </h4>
        <input
          type="text"
          css={{
            width: "65%",
            height: "30px",
            borderRadius: "15px",
            padding: "0px 10px",
            [s.x500]: {
              margin: "auto",
            },
          }}
          placeholder="RGB style eg: rgb(225,225,225)"
          onChange={e => {
            setNeonColor(e.target.value)
          }}
          onKeyUp={e => {
            if (e.code === "Enter") {
              dispatch({ type: "CHANGE_NEON_COLOR", payload: e.target.value })
            }
          }}
        />
        {/* <Button
          title="APPLY"
          color={"red"}
          func={() => {
            dispatch({ type: "CHANGE_NEON_COLOR", payload: neonColor })
          }}
        /> */}
        <div
          css={{
            marginTop: "20px",
            width: "30px",
            height: "30px",
            backgroundColor: neonColor,
            opacity: "0.7",
            border: "1px solid white",
            borderRadius: "15px",
            cursor: "pointer",
            ":hover": {
              opacity: "1",
            },
            [s.x500]: {
              width: "50px",
              height: "50px",
              margin: "20px auto 0px",
            },
          }}
          onClick={() => {
            dispatch({ type: "CHANGE_NEON_COLOR", payload: neonColor })
          }}
        />
      </div>
    </NeonWrapper>
  )
}

export default NeonOptions
