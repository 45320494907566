/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import { React, useState, useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../layout.css"
import Header from "./header/header"
import CheckoutNav from "./checkoutNav/checkoutNav"
import CheckoutButton from "./checkoutButton/checkoutButton"
import { DispatchContext, Context } from "../../store/GlobalStateProvider"
import MobileNav from "./header/mobileNav/mobileNav"
import { navElements } from "./header/content"
import Notifications from "./notifications/notifications"
import Footer from "./footer/footer"
import NeonOptions from "../buttons/neonMode/NeonOptions"

const Layout = props => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(Context)
  const neonsActualColor = state.neonMode.color
  const neonsOn = state.neonMode.isActive
  const servicesNumber = state.servicesCheckout.length
  const notifications = state.notifications
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const path = props?.location?.pathname
  const [isCheckoutNavOpen, setIsCheckoutNavOpen] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [optionsActive, setOptionsActive] = useState(false)
  return (
    <div>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        path={path}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        navElements={navElements}
        optionsActive={optionsActive}
        setOptionsActive={setOptionsActive}
      />

      <CheckoutNav
        isCheckoutNavOpen={isCheckoutNavOpen}
        setIsCheckoutNavOpen={setIsCheckoutNavOpen}
        services={state.servicesCheckout}
        dispatch={dispatch}
      />

      {servicesNumber > 0 && (
        <CheckoutButton
          isCheckoutNavOpen={isCheckoutNavOpen}
          setIsCheckoutNavOpen={setIsCheckoutNavOpen}
          servicesNumber={servicesNumber}
        />
      )}
      {optionsActive === true && (
        <NeonOptions
          setOptionsActive={setOptionsActive}
          dispatch={dispatch}
          neonsActualColor={neonsActualColor}
          neonsOn={neonsOn}
        />
      )}
      <MobileNav
        open={isMobileMenuOpen}
        navElements={navElements}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <main>{props.children}</main>
      <Notifications notifications={notifications} dispatch={dispatch} />

      <Footer neonsActualColor={neonsOn && neonsActualColor} />
    </div>
  )
}

export default Layout
