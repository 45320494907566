import React from "react"
import { Link } from "gatsby"

const LinkButton = ({ title, link, color, additionalCss, ...props }) => {
  const style = {
    button: {
      color: "#fff",
      width: 130,
      height: 40,
      padding: "10px 25px",
      fontWeight: 500,
      background: "transparent",
      cursor: "pointer",
      transition: "all 0.3s ease",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      backgroundColor: `${color}`,
      boxShadow: `0 0 5px ${color}`,
      borderRadius: "15px",
      fontSize: "1.3rem",
      textDecoration: "none",
      ":hover": {
        boxShadow:
          !props.isServiceAlreadyAdded &&
          `0 0 10px ${color}, 0 0 20px ${color}, 0 0 20px #fff inset`,
      },
    },
  }
  return (
    <Link to={link} css={[style.button, additionalCss]} target="_blank">
      {title}
    </Link>
  )
}

export default LinkButton
