import { React, useState } from "react"
import volumeOn from "../../media/images/icons/volume-on.png"
import volumeOff from "../../media/images/icons/volume-off.png"

const AudioButton = ({ music, size }) => {
  const [isMuted, setIsMuted] = useState(true)
  return (
    <div
      role="button"
      tabIndex={"0"}
      css={{
        width: size,
        height: size,
        borderRadius: "50%",
        opacity: 1,
        zIndex: 13,
        cursor: "pointer",
      }}
      onClick={() => {
        isMuted ? setIsMuted(false) : setIsMuted(true)
        isMuted ? music.current.play() : music.current.pause()
      }}
      onKeyUp={e => {
        if (e.code === "Enter") {
          isMuted ? setIsMuted(false) : setIsMuted(true)
          isMuted ? music.current.play() : music.current.pause()
        }
      }}
    >
      <img
        css={{ width: "100%", height: "100%" }}
        src={isMuted ? volumeOff : volumeOn}
        alt=""
      />
    </div>
  )
}

AudioButton.displayName = "AudioButton"
export default AudioButton
