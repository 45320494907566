import { React } from "react"
import useDisableBodyScroll from "../../../../utils/useDisableBodyScroll"
import { Link } from "gatsby"
const MobileNav = ({ open, navElements, setIsMobileMenuOpen }) => {
  useDisableBodyScroll(open)

  return (
    <div
      css={{
        position: "fixed",
        top: 100,
        left: 0,
        width: "100%",
        height: "100vh",
        backdropFilter: "blur(6px)",
        backgroundColor: "rgba(0,0,0,0.3)",
        opacity: open ? 1 : 0,
        zIndex: open ? 99999999 : -10,
        transition: "all 0.3s ease-in-out",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "50px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "100px",
        }}
      >
        {navElements
          .sort((a, b) => {
            return a.position - b.position
          })
          .map(el => {
            return (
              <Link
                to={el.url}
                css={{
                  fontSize: "1.5em",
                  textDecoration: "none",
                  color: "white",
                }}
                onClick={() => {
                  setIsMobileMenuOpen(!open)
                }}
              >
                {el.title}
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default MobileNav
