import { React } from "react"

const PointingArrows = ({ size, additionalCss }) => {
  const base = size ? size : 0.6
  const style = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    chevron: {
      position: "absolute",
      width: `${base * 3.5}rem`,
      height: `${base * 0.8}rem`,
      opacity: 0,
      transform: "scale(0.3)",
      animation: "move-chevron 3s ease-out infinite",
      ":first-child": { animation: "move-chevron 3s ease-out 1s infinite" },
      ":nth-child(2)": {
        animation: "move-chevron 3s ease-out 2s infinite",
      },
      ":before,:after": {
        content: "''",
        position: "absolute",
        top: 0,
        height: "100%",
        width: "50%",
        background: "#ffffff",
      },
      ":after": {
        right: 0,
        width: "50%",
        transform: "skewY(-30deg)",
      },
      ":before": {
        left: 0,
        transform: "skewY(30deg)",
      },
      "@keyframes move-chevron ": {
        "25%": {
          opacity: 1,
        },
        "33.3%": {
          opacity: 1,
          transform: `translateY(${base * 3.8}rem)`,
        },
        "66.6%": {
          opacity: 1,
          transform: `translateY(${base * 5.2}rem)`,
        },
        "100%": {
          opacity: 0,
          transform: `translateY(${base * 8}rem) scale(0.5)`,
        },
      },
    },
  }
  return (
    <div css={[style.container, additionalCss]}>
      <div css={style.chevron}></div>
      <div css={style.chevron}></div>
      <div css={style.chevron}></div>
    </div>
  )
}

export default PointingArrows
