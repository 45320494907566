export const content = {
  links: [{ title: "Home", link: "/" }],
  contact: [
    { icon: "phone", link: "tel:+353831166159", title: "+353 83 116 6159" },
    {
      icon: "email",
      link: "mailto:k.koch@zohomail.eu",
      title: "k.koch@zohomail.eu",
    },
  ],
  socials: [
    {
      icon: "facebook",
      link: "https://www.facebook.com/people/Aderner-Projects-Studio/61551862292200/",
    },
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/company/aderner-projects-studio/",
    },
    { icon: "twitter", link: "https://twitter.com/adernerprojects/" },
  ],
}
