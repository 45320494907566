import React, { useReducer } from "react"

export const Context = React.createContext()
export const DispatchContext = React.createContext()

export const initialState = {
  servicesCheckout:
    JSON.parse(
      typeof window !== "undefined" && sessionStorage?.getItem("servicesAdded")
    ) || [],
  notifications: [],
  neonMode: { isActive: true, color: "lightgreen" },
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_NEON_MODE":
      return {
        ...state,
        neonMode: { ...state.neonMode, isActive: action.payload },
      }
    case "CHANGE_NEON_COLOR":
      return {
        ...state,
        neonMode: { ...state.neonMode, color: action.payload },
      }
    case "ADD_SERVICE":
      return {
        ...state,
        servicesCheckout: [...state.servicesCheckout, action.payload],
      }
    case "DELETE_SERVICE":
      return {
        ...state,
        servicesCheckout: [
          ...state.servicesCheckout.filter(e => action.payload !== e),
        ],
      }
    case "ADD_NOTIFICATION":
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      }
    case "DELETE_NOTIFICATION":
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(e => action.payload !== e),
        ],
      }
    default:
      return
  }
}

const GlobalStateProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </Context.Provider>
  )
}

export default GlobalStateProvider
