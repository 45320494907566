import { React, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Notifications = ({ notifications, dispatch }) => {
  const data = useStaticQuery(graphql`
    query {
      success: file(name: { regex: "/success_icon/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 25, placeholder: BLURRED, formats: AUTO)
        }
      }
      alert: file(name: { regex: "/alert_icon/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 35, placeholder: BLURRED, formats: AUTO)
        }
      }
      error: file(name: { regex: "/error_icon/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 35, placeholder: BLURRED, formats: AUTO)
        }
      }
      close: file(name: { regex: "/close_round_icon/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 25, placeholder: BLURRED, formats: AUTO)
        }
      }
    }
  `)
  useEffect(() => {
    notifications.length !== 0 &&
      setTimeout(() => {
        dispatch({ type: "DELETE_NOTIFICATION", payload: notifications[0] })
      }, 10000)
  }, [notifications])
  console.log(notifications)
  return (
    <div
      css={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        borderRadius: "15px",
        zIndex: 999999999,
        height: `${notifications.length * 50}px`,
        transition: "all 0.5s linear",
      }}
    >
      {notifications?.map((notification, id) => {
        console.log(id)
        const color =
          notification.type === "alert"
            ? "orange"
            : notification.type === "success"
            ? "green"
            : "red"
        const image = getImage(data[notification.type])
        const closeIcon = getImage(data["close"])
        const keyFrames = `@keyframes moveOpen${id}`
        return (
          <div
            css={{
              width: "100%",
              height: "50px",
              background: color,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "15px",
              top: 0,
              animation: `moveOpen${id} 0.5s forwards`,
              [keyFrames]: {
                from: { transform: "translate(0,50px)" },
                to: { transform: `translate(0,${0}px)` },
              },
            }}
          >
            <GatsbyImage image={image} alt="" css={{ marginLeft: "15px" }} />
            {notification?.message}
            <div
              css={{ cursor: "pointer" }}
              onClick={() => {
                dispatch({ type: "DELETE_NOTIFICATION", payload: notification })
              }}
            >
              {" "}
              <GatsbyImage
                image={closeIcon}
                alt=""
                css={{ marginRight: "15px" }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Notifications
