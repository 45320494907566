import React from "react"

const TitleTypo = ({ h, title, size, align, additionalCss, color }) => {
  const CustomTag = `h${h ? h : 1}`
  return (
    <CustomTag
      css={[
        {
          fontSize: `${size ? size : 1}rem`,
          textAlign: `${align}`,
          color: "white",
          lineHeight: "1.25em",
          fontWeight: 800,
          letterSpacing: "0.025em",
          textTransform: "uppercase",
          margin: "1rem 0rem 0.75rem",
          background:
            color === "rainbow"
              ? "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red)"
              : `linear-gradient(to right, ${
                  color ? color : "#FFFFFF"
                }, #bfbfbf)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
        additionalCss,
      ]}
    >
      {title}
    </CustomTag>
  )
}

export default TitleTypo
