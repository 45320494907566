import { React } from "react"

const Hamburger = ({ open, setOpen }) => {
  const style = DynamicCSS(open)
  return (
    <div
      role="button"
      tabIndex={"0"}
      css={style.navIcon}
      onClick={() => {
        open === false ? setOpen(true) : setOpen(false)
      }}
      onKeyUp={e => {
        if (e.code === "Enter") {
          open === false ? setOpen(true) : setOpen(false)
        }
      }}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
const DynamicCSS = open => {
  return {
    navIcon: {
      width: 40,
      height: 30,
      position: "relative",
      transform: "rotate(0deg)",
      transition: ".5s ease-in-out",
      cursor: "pointer",
      marginRight: "14px",
      zIndex: 11,
      span: {
        display: "block",
        position: "absolute",
        height: 6,
        width: "100%",
        background: "#fff",
        borderRadius: 6,
        opacity: 1,
        left: 0,
        transform: "rotate(0deg)",
        transition: ".25s ease-in-out",
      },
      "span:nth-child(1)":
        open === false
          ? {
              top: 0,
            }
          : { top: "12px", width: "0%", left: "50%" },
      "span:nth-child(2)":
        open === false
          ? {
              top: 12,
            }
          : {
              top: 12,
              transform: "rotate(45deg)",
            },
      "span:nth-child(3)":
        open === false
          ? {
              top: 12,
            }
          : {
              top: 12,
              transform: "rotate(-45deg)",
            },
      "span:nth-child(4)":
        open === false
          ? {
              top: 24,
            }
          : {
              top: 12,
              width: "0%",
              left: "50%",
            },
    },
  }
}
export default Hamburger
