export const navElements = [
  {
    title: "Home",
    url: "/",
    position: 1,
  },
  {
    title: "Services",
    url: "/services",
    position: 2,
  },
  {
    title: "Contact",
    url: "/contact",
    position: 3,
  },
  { title: "About Us", url: "/about-us", position: 4 },
]
