import { React } from "react"
import TitleTypo from "./../../typography/customTypo/titleTypo"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { content } from "./content"
import { navElements } from "../header/content"
import { s } from "../../../style"

const Footer = ({ neonsActualColor }) => {
  const data = useStaticQuery(graphql`
    query {
      email: file(name: { regex: "/email/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 25, placeholder: BLURRED, formats: AUTO)
        }
      }
      phone: file(name: { regex: "/phone/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 25, placeholder: BLURRED, formats: AUTO)
        }
      }
      linkedin: file(name: { regex: "/linkedin_icon_x256/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: BLURRED, formats: AUTO)
        }
      }
      facebook: file(name: { regex: "/facebook_icon_x256/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: BLURRED, formats: AUTO)
        }
      }
      twitter: file(name: { regex: "/twitter_icon_x256/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: BLURRED, formats: AUTO)
        }
      }
    }
  `)
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "30px 0px",
        position: "relative",
        margin: "0px 50px",

        a: {
          fontSize: "1.4em",
          color: "white",
          textDecoration: "none",
        },
        [s.tablet]: {
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: "30px",
        },
      }}
    >
      <div
        css={{
          position: "absolute",
          top: 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          css={{
            height: "3px",
            background: "#fff",
            width: "60%",
            position: "absolute",
          }}
        />
      </div>
      <div>
        <TitleTypo
          h={4}
          title={"Quick Links:"}
          size={1.5}
          color={neonsActualColor}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 15,
          }}
        >
          {navElements.map(link => (
            <Link
              to={link.url}
              css={{
                ":hover": {
                  color: `${neonsActualColor}`,
                  textShadow: `0px 0px 20px ${neonsActualColor}, 0px 0px 50px ${neonsActualColor}`,
                  filter: "brightness(150%)",
                },
              }}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          alignItems: "center",
        }}
      >
        <TitleTypo
          h={4}
          title={"Contact Us:"}
          size={1.5}
          color={neonsActualColor}
        />
        {content.contact.map(contact => (
          <div css={{ display: "flex-block" }}>
            <GatsbyImage image={getImage(data[contact.icon])} alt="" />
            <a
              href={contact.link}
              css={{
                marginLeft: 10,
                ":hover": {
                  color: `${neonsActualColor}`,
                  textShadow: `0px 0px 20px ${neonsActualColor}, 0px 0px 50px ${neonsActualColor}`,
                  filter: "brightness(150%)",
                },
              }}
            >
              {contact.title}
            </a>
          </div>
        ))}
      </div>
      <div css={{ display: "flex", flexDirection: "column" }}>
        <TitleTypo
          h={4}
          title={"Check our socials:"}
          size={1.5}
          color={neonsActualColor}
        />
        <div
          css={{
            display: "flex",
            gap: 10,
            [s.tablet]: {
              justifyContent: "center",
            },
          }}
        >
          {content.socials.map(social => (
            <div>
              <a href={social.link} target="_blank" rel="noreferrer">
                <GatsbyImage
                  image={getImage(data[social.icon])}
                  alt=""
                  css={{
                    filter: "grayscale(1)",
                    ":hover": {
                      filter: "grayscale(0)",
                    },
                    [s.tablet]: {
                      filter: "grayscale(0)",
                    },
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Footer
