import * as React from "react"

const NeonWrapper = ({ children, additionalCss, color, isActive }) => {
  !color ? (color = "rgb(255,255,255)") : (color = color)
  let color1 = 0
  let color2 = 0
  let color3 = 0
  let color4 = 0
  if (!color) {
    color1 = "rgb(255,255,255)"
  }
  if (typeof color === "string") {
    color1 = color
  } else if (Array.isArray(color)) {
    color1 = color[0] && color[0]
    color2 = color[1] && color[1]
    color3 = color[2] && color[2]
    color4 = color[3] && color[3]
  }

  const newColor = color1.slice(0, color.length - 2) + "0.5)"
  const style = {
    wrapper: {
      border: `3px solid`,
      borderTopColor: `${color1}`,
      borderRightColor: `${color3 ? (color2 ? color2 : color1) : color1}`,
      borderBottomColor: `${color3 ? color3 : color2 ? color2 : color1}`,
      borderLeftColor: `${
        color4 ? color4 : color3 ? color3 : color2 ? color2 : color1
      }`,
      borderRadius: "25px",
      overflow: "hidden",
      active: {
        border: "3px solid #fff",
        borderRadius: "25px",
        boxShadow: `0 0 .2rem rgba(255,255,255,1), 0 0 .2rem rgba(255,255,255,1), 0 0 2rem ${color1}, 0 0 0.8rem ${color1}, 0 0 2.8rem ${color1}, inset 0 0 1.3rem ${color1}`,
        overflow: "hidden",
      },
    },
  }
  return (
    <div
      css={[
        additionalCss,
        isActive === true ? style.wrapper.active : style.wrapper,
      ]}
    >
      {children}
    </div>
  )
}
NeonWrapper.displayName = "Neon Wrapper"
export default NeonWrapper
