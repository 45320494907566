export const hd = "@media screen and (max-width: 1920px)"
export const hs = "@media screen and (max-width: 1280px)"
export const tablet = "@media screen and (max-width: 1098px)"
export const desktop = "@media screen and (min-width: 1098px)"
export const md = "@media screen and (max-width: 768px)"
export const x500 = "@media screen and (max-width: 500px)"
export const x800 = "@media screen and (max-width: 800px)"
export const xs = "@media screen and (max-width: 380px)"

export const uhd = "@media screen and (min-width: 2160px)"
export const hover = "@media (hover:hover) and (pointer: fine)"
export const touch = "@media (hover: none)"

export const s = {
  hd,
  hs,
  md,
  xs,
  x500,
  x800,
  tablet,
  desktop,

  uhd,
  touch,
  hover,
}
