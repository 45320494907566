import { React, useState } from "react"
import NeonOptions from "./NeonOptions"

const NeonModeButton = ({
  additionalCss,
  size,
  dispatch,
  neonsActualColor,
  neonsOn,
  optionsActive,
  setOptionsActive,
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [delayHandler, setDelayHandler] = useState(null)
  const style = {
    switch: {
      position: "relative",
      width: size ? size : 35,
      height: size ? size : 35,
      backgroundColor: neonsOn ? neonsActualColor : "rgb(99, 99, 99)",
      borderRadius: "50%",
      zIndex: 1,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: neonsOn
        ? "2px solid rgb(255, 255, 255)"
        : "2px solid rgb(126, 126, 126)",
      boxShadow: neonsOn
        ? `0px 0px 10px ${neonsActualColor}, 0px 0px 5px ${neonsActualColor} inset`
        : "0px 0px 3px rgb(2, 2, 2) inset",
    },
    powerSign: {
      position: "relative",
      width: "30%",
      height: "30%",
      border: neonsOn
        ? "2px solid rgb(255, 255, 255)"
        : "2px solid rgb(48, 48, 48)",
      boxShadow: neonsOn
        ? `0px 0px 10px ${neonsActualColor}, 0px 0px 5px ${neonsActualColor} inset`
        : "unset",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "::before": {
        content: '""',
        width: 6,
        height: "100%",
        backgroundColor: neonsOn ? neonsActualColor : "rgb(99, 99, 99)",
        position: "absolute",
        top: "-60%",
        zIndex: 2,
      },
      "::after": {
        content: '""',
        width: 2,
        height: "100%",
        backgroundColor: neonsOn ? "rgb(255, 255, 255)" : "rgb(48, 48, 48)",
        boxShadow: neonsOn ? "0px 0px 5px rgb(151, 243, 255)" : "unset",
        position: "absolute",
        top: "-60%",
        zIndex: 3,
      },
    },
  }
  return (
    <div css={[additionalCss]}>
      <div
        css={style.switch}
        onClick={() => {
          optionsActive ? setOptionsActive(false) : setOptionsActive(true)
          console.log(optionsActive)
        }}
      >
        <div css={style.powerSign}></div>
      </div>
    </div>
  )
}
NeonModeButton.displayName = "NeonModeButton"
export default NeonModeButton
