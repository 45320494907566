import "./src/components/layout.css"
import React from "react"
import Layout from "./src/components/layout/layout"
import GlobalStateProvider from "./src/store/GlobalStateProvider"

export const wrapRootElement = ({ element, props }) => (
  <GlobalStateProvider>
    <Layout {...props}>{element}</Layout>
  </GlobalStateProvider>
)
