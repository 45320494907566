import React, { useState } from "react"

const NeonToggleButton = ({ dispatch, neonsActualColor, neonsOn }) => {
  const [isClicked, setIsClicked] = useState(neonsOn)

  const style = {
    toggleWrapper: {
      width: "55px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      borderRadius: 99,
      padding: ".125em",
      backgroundImage: "linear-gradient(to bottom, #777676, #212121)",
      boxShadow: "0 1px 1px rgba(0, 0, 0, 0.6)",
      /* resize for demo */
      fontSize: "1.5em",
    },
    toggleCheckBox: {
      appearance: "none",
      position: "absolute",
      zIndex: 1,
      borderRadius: "inherit",
      width: "100%",
      height: "100%",
      /* fix em sizing */
      font: "inherit",
      opacity: 0,
      cursor: "pointer",
    },
    toggleContainer: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      borderRadius: 99,
      width: "2em",
      height: "1em",
      backgroundColor: isClicked ? neonsActualColor : "#212121",
      boxShadow:
        "inset 0 0 .0625em .125em rgba(5, 5, 5, 0.2), inset 0 .0625em .125em rgba(0, 0, 0, 0.4)",
      transition: "background-color .4s linear",
    },
    toggleButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      left: isClicked ? ".995em" : ".0625em",
      borderRadius: 99,
      width: "1em",
      height: "1em",
      backgroundColor: "#212121",
      boxShadow:
        "inset 0 -.0625em .0625em .125em rgba(5, 5, 5, 0.2), inset 0 -.125em .0625em rgba(5, 5, 5, 0.2), inset 0 .1875em .0625em rgba(219, 219, 219, 0.3), 0 .125em .125em rgb(0 0 0 / .5)",
      transition: "left .4s",
    },
    toggleButtonCirclesContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, min-content)",
      gap: ".125em",
      position: "absolute",
      margin: "0 auto",
    },
    toggleButtonCircle: {
      borderRadius: "50%",
      width: ".1em",
      height: ".1em",
      backgroundImage: "radial-gradient(circle at 50% 0, #5e5d5d, #807d7d)",
    },
    // .toggle-checkbox:checked + .toggle-container > .toggle-button {
    //   left: 1.5625em;
    // }
    // .toggle-checkbox:checked + .toggle-container {
    //   background-color: rgba(11, 108, 253, 0.1);
    // }
  }

  return (
    <div css={style.toggleWrapper}>
      <input
        type="checkbox"
        css={style.toggleCheckBox}
        onClick={() => {
          isClicked ? setIsClicked(false) : setIsClicked(true)
          dispatch({
            type: "CHANGE_NEON_MODE",
            payload: isClicked ? false : true,
          })
        }}
      />
      <div css={style.toggleContainer}>
        <div css={style.toggleButton}>
          <div css={style.toggleButtonCirclesContainer}>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
            <div css={style.toggleButtonCircle}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

NeonToggleButton.displayName = "NeonToggleButton"
export default NeonToggleButton
