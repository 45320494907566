import { React, useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PointingArrows from "../../animations/pointingArrows"

const CheckoutButton = ({
  isCheckoutNavOpen,
  setIsCheckoutNavOpen,
  servicesNumber,
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { regex: "/checkout/" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 35, placeholder: BLURRED, formats: AUTO)
        }
      }
    }
  `)
  const [hintActive, setHintActive] = useState(true)
  const prevServiceNumber = useRef(0)

  useEffect(() => {
    if (prevServiceNumber.current < servicesNumber) {
      setHintActive(true)
    } else {
      setHintActive(false)
    }
    prevServiceNumber.current = servicesNumber
  }, [servicesNumber])
  return (
    <div
      css={{
        display: isCheckoutNavOpen ? "none" : "inline-block",
        position: "fixed",
        bottom: 25,
        right: 25,
        zIndex: 999,
      }}
    >
      {hintActive && (
        <PointingArrows size={0.5} additionalCss={{ height: "110px" }} />
      )}
      <div
        css={{
          position: "relative",
          width: "50px",
          height: "50px",
          display: "flex",
          border: "3px solid white",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(20px)",
          cursor: "pointer",
          "::after": {
            content: `"${servicesNumber}"`,
            textAlign: "center",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "orange",
            display: "inline-block",
            position: "absolute",
            bottom: 0,
            right: 0,
          },
        }}
        onMouseEnter={() => {
          setHintActive(false)
          typeof window !== "undefined" && sessionStorage.setItem("ha", false)
        }}
        onClick={() => {
          setIsCheckoutNavOpen(true)
        }}
      >
        <GatsbyImage image={getImage(data.file)} alt="" />
      </div>
    </div>
  )
}

export default CheckoutButton
